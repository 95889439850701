<template>
	<div class="download-page">
		<div class="main-page">

			<div class="main-block">
				<div class="main-name">{{ $t('exploreTab_property') }}</div>
				<div class="main-content">
					<div class="item">
						<div style="font-size: 20px;">
							<div class="title">
								{{ $t('IOSDownload') }}
							</div>
						</div>
						<div class="outer-circle" @click="IOSDownload">
							<div class="inner-circle">
								<img src="../../assets/IOS.png" style="width: 150px;height: 150px;" alt="图片丢了嘿~">
								<img src="../../assets/IOS.png" style="width: 150px;height: 150px;" alt="图片丢了嘿~">
							</div>
						</div>
					</div>
					<div class="item">
						<div style="font-size: 20px;">
							<div class="title">
								{{ $t('AndroidDownload') }}
							</div>
						</div>
						<div class="outer-circle" @click="AndroidDownload">
							<div class="inner-circle">
								<img src="../../assets/Android.png" style="width: 150px;height: 150px;" alt="图片丢了嘿~">
								<img src="../../assets/download_wuye.png" style="width: 150px;height: 150px;" alt="图片丢了嘿~">
							</div>
						</div>
					</div>
				</div>
			</div>
			
			
			<div class="main-block">
				<div class="main-name">{{ $t('exploreTab_owner') }}</div>
				<div class="main-content">
					<div class="item">
						<div style="font-size: 20px;">
							<div class="title">
								{{ $t('IOSDownload') }}
							</div>
						</div>
						<div class="outer-circle" @click="IOSDownload">
							<div class="inner-circle">
								<img src="../../assets/IOS.png" style="width: 150px;height: 150px;" alt="图片丢了嘿~">
								<img src="../../assets/IOS.png" style="width: 150px;height: 150px;" alt="图片丢了嘿~">
							</div>
						</div>
					</div>
					<div class="item">
						<div style="font-size: 20px;">
							<div class="title">
								{{ $t('AndroidDownload') }}
							</div>
						</div>
						<div class="outer-circle" @click="AndroidDownload">
							<div class="inner-circle">
								<img src="../../assets/Android.png" style="width: 150px;height: 150px;" alt="图片丢了嘿~">
								<img src="../../assets/download_yezhu.png" style="width: 150px;height: 150px;" alt="图片丢了嘿~">
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="main-block">
				<div class="main-name">{{ $t('exploreTab_merchant') }}</div>
				<div class="main-content">
					<div class="item">
						<div style="font-size: 20px;">
							<div class="title">
								{{ $t('IOSDownload') }}
							</div>
						</div>
						<div class="outer-circle" @click="IOSDownload">
							<div class="inner-circle">
								<img src="../../assets/IOS.png" style="width: 150px;height: 150px;" alt="图片丢了嘿~">
								<img src="../../assets/IOS.png" style="width: 150px;height: 150px;" alt="图片丢了嘿~">
							</div>
						</div>
					</div>
					<div class="item">
						<div style="font-size: 20px;">
							<div class="title">
								{{ $t('AndroidDownload') }}
							</div>
						</div>
						<div class="outer-circle" @click="AndroidDownload">
							<div class="inner-circle">
								<img src="../../assets/Android.png" style="width: 150px;height: 150px;" alt="图片丢了嘿~">
								<img src="../../assets/download_shangjia.png" style="width: 150px;height: 150px;" alt="图片丢了嘿~">
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		name: 'download',
		methods: {
			IOSDownload() {
				this.$message.info({
					showClose: false,
					message: 'IOS 敬请期待~'
				})
			},
			AndroidDownload() {
				window.open('https://u-job-go.oss-cn-guangzhou.aliyuncs.com/apk/shwy.apk')
				// this.$message.info({
				//   showClose: false,
				//   message: 'Android 敬请期待~'
				// })
			}
		}
	}
</script>

<style scoped lang="scss">
	.download-page {
		display: flex;
		flex-direction: column;
		width: 100vw;
			margin-top: 60px;
		// height: 100vh;

		.title {
			background-image: -webkit-linear-gradient(left, #1684e1 1%, #734BEB 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		.title-page {
			width: 25%;
			margin-top: 70px;
			margin-left: 20px;
			font-size: 70px;
			height: 130px;
		}

		.main-page {
			//background-color: #108EE9;
			width: 100%;
			// margin: 30px 0;
			// height: 100%;
			// display: flex;
			// align-items: center;
			// justify-content: center;

			.item {
				//background-color: #42b983;
				width: 280px;
				height: 280px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.outer-circle {
					background: linear-gradient(to right, #108EE9 20%, #7949EB 100%);
					width: 250px;
					height: 250px;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;

					.inner-circle {
						position: relative;
						background-color: #FFFFFF;
						width: 240px;
						height: 240px;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;

						img:last-child {
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
							opacity: 0;
							cursor: none;
						}

						img:last-child:hover {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	.main-block {
		width: 1200px;
		margin: 30px auto;
	}

	.main-name {
		background-image: -webkit-linear-gradient(left, #1684e1 1%, #734BEB 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 50px;
		font-weight: 700;
	}

	.main-content {
		display: flex;
		justify-content: space-around;
		margin-top: 30px;
	}
</style>